import styled from 'styled-components'
import brocha from '../../images/launch_button.png'

export const SpikeHome = styled.div`
  display:flex;
  justify-content: center;
  position: relative;
  align-items: center;
`
export const SpikeContainer = styled.div`
  display: flex;
  margin-top: 80px;
  width: 100%;
  height: 100%;
  max-width: 1920px;

  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0.2)0%, rgba(0,0,0,0.6)100%),  
    linear-gradient(180deg, rgba(0,0,0,0.2)0%, transparent 100%)
    z-index: 2;
  }

  @media screen and (max-width: 1224px) {
    margin-top: 75px;
    position: ;
  }

`
export const SpikeOne = styled.div`
  display: flex;
  width: 100%;
  max-width: 1920px;
  position: relative;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 1224px) {
    flex-direction: column;
  }
`
export const SpikeLeft = styled.div`
  display: flex;
  width: 50%;
  max-width: 700px;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 1224px) {
    height: 50%;
    width: 100%;
    max-width: 100%;
  }
`
export const SocialButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  
  @media screen and (max-width: 1224px) {
    margin-top: 10px;
  }

`

export const BtnBox = styled.div`
  display: flex;
`

export const SpikeRight = styled.div`
  display: flex;
  width: 50%;
  max-width: 739px;
  align-items: flex-end;

  @media screen and (max-width: 1224px) {
    height: 50%;
    width: 100%;
    max-width: 100%;
  }
`

export const Logo = styled.img`
  margin-top: -40px;
  width: 100%;
  user-select: none;

  @media screen and (max-width: 1224px) {
    margin-top: 0px;
  }
`
export const SocialBtn = styled.img`
  width: 100%;
  opacity: 0.9;
  user-select: none;

  &:hover {
    opacity: 1;
    transition: 0.1s ease-in-out;
  }
}
`

export const Spray = styled.img`
  width: 100%;
  user-select: none;

  @media screen and (max-width: 1224px) {
    margin-top: 15px;
  }
`
export const SuscribeBox = styled.div`
  display: flex;  
  margin-top: 15px;
  padding: 10px;
  width: 100%;
  border-radius: 64px;
  background-color: transparent;
  border-style: solid;
  border-color: white;
  border-width: thin;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding: 0px;
    margin-bottom: 15px;
  }  
` 

export const SubscribeField = styled.input`
  
  width: 95%;
  background-color: transparent;
  text-align: center;
  font-family: "LemonMilk-regular";
  font-size: 14px;
  color: white;
  border: none;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (max-width: 767px) {
    font-size: 13px;
    width: 90%;
  }  
`
export const SubscribeIconLink = styled.a`
  color: white;
  font-size: 24px;
`

export const BrochaBox = styled.div`
  display: flex;
  flex-direction: row; 
  width: 85%;
  height: 170px;
  align-items: center;

  background-image: url(${brocha});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 1224px) {
   margin-right: 20px;
    width: 70%;
    height: 100%;
  }

` 

export const Marketplace = styled.img`
  width: 10%;
  margin: 0px 10px 5px 10px;
  user-select: none;

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.1s ease-in-out;
  }

  @media screen and (max-width: 1224px) {
    width: 9%;
    margin: 0px 5px 5px 10px;
  }
`
export const MarketplaceTextH1 = styled.div`
  font-family: "Dumbnerd";
  font-size: 4vw;
  text-align: center;
  color: white;

  margin-left: 70px;
  margin-right: 30px;

  @media screen and (max-width: 1224px) {
    font-size: 7vw;
    margin-left: 10vw;
    margin-right: 2vw;
  }


`