import React from "react";

import title from '../../images/faqs.png'

import { FAQHome, FAQContainer, FAQTitle, FAQImageTitle, FaqDescription, FaqQuestionH1, FaqAnswerH2} from "./FAQElements";

function FAQ() {
  return (
    <FAQHome id="faq">
      <FAQContainer >
        <FAQTitle>
          <FAQImageTitle src={title} type='image/png' />
        </FAQTitle>
        <FaqDescription>
          <FaqQuestionH1>WHEN IS THE OFFICIAL LAUNCH AND WHAT WILL THE MINT PRICE BE?</FaqQuestionH1>
          <FaqAnswerH2>NOVEMBER 12, 2022 - WL PRICE 0.069 ETH. FIRST PUBLIC PRICE: 0.0969 ETH. SECOND PUBLIC PRICE: 0.055. FREE MINT STAGE: FREE.</FaqAnswerH2>
          <FaqQuestionH1>HOW MANY NFT COULD I MINT?</FaqQuestionH1>
          <FaqAnswerH2>WHITELIST & OG ROLES COULD MINT 3 PER WALLET. PUBLIC SALE: 4 PER WALLET. FREE MINT: 1 PER WALLET. </FaqAnswerH2>
          <FaqQuestionH1>HOW DO I GET WHITELISTED?</FaqQuestionH1>
          <FaqAnswerH2>WHITELIST PROCESS IS OVER. WE WILL UPDATE THIS INFO IN THE SECOND COLLECTION ROUND.</FaqAnswerH2>
          <FaqQuestionH1>WHICH BENEFITS HAVE BRAINLESS SPIKES COLLECTION?</FaqQuestionH1>
          <FaqAnswerH2>BRAINLESS SPIKES IS THE FIRST NFT COLLECTION TO CREATE A COLLECTIVE 3D ANIMATION SERIES WITH WORKING TOGETHER WITH COMMUNITY. THE SERIES WILL BE DEVELOPED BY FIRELIGHT ENTERTAINMENT. 50% OF THE PROFITS THAT WE MAKE WITH THE SERIES WILL BE TRANSFERRED TO OUR HOLDERS, AS WELL AS THE 50% OF EVERY FUTURE PROJECT THE COMPANY CREATES.</FaqAnswerH2>
          <FaqQuestionH1>ALL THE NFT HAVE 3D ANIMATION SERIES ROYALTIES?</FaqQuestionH1>
          <FaqAnswerH2>NO, JUST SOLDIERS, EPICS, LEGENDARIES AND GIFT RARITIES. ALL OF THEM ARE INCLUDED IN THE 1-1000 TOKEN ID'S.</FaqAnswerH2>
          <FaqQuestionH1>ALL THE NFT HAVE REVENUE ON ETH?</FaqQuestionH1>
          <FaqAnswerH2>YES. ALL RARITIES AND ID's WILL SPLIT THE 50% OF ALL MARKETING & BRANDS CAMPAIGNS ON SOCIAL MEDIA. SWORDS INCLUDED. </FaqAnswerH2>
          <FaqQuestionH1>DOES THE SWORD RARITY HAVE UTILITIES?</FaqQuestionH1>
          <FaqAnswerH2>YES. JUMP IN TO OUR WIKI REWARDS PAGE</FaqAnswerH2>
          <FaqQuestionH1>WHICH WALLETS CAN I USE FOR MINTING?</FaqQuestionH1>
          <FaqAnswerH2>YOU WILL BE ABLE TO USE POPULAR ETHEREUM WALLETS, LIKE METAMASK.</FaqAnswerH2>
          <FaqQuestionH1>HOW CAN I CONTACT THE TEAM?</FaqQuestionH1>
          <FaqAnswerH2>YOU CAN REACH US ON DISCORD, OUR SOCIAL MEDIA CHANNELS AND BY EMAIL TO INFO@BRAINLESSPIKES.IO</FaqAnswerH2>
        </FaqDescription>
      </FAQContainer>
    </FAQHome>
  );
}

export default FAQ;
