import React, {useState} from 'react'

import Sidebar from '../components/SideBar'
import Navbar from '../components/Navbar'
import Spike from '../components/Spike'
import Merch from '../components/Merch'
import Jungle from '../components/Jungle'
import Features from '../components/Features'
import Roadmap from "../components/Roadmap"
import Team from '../components/Team'
import Partners from '../components/Partners'
import Rarity from '../components/Rarity'
import Subscribe from '../components/Subscribe'
import FAQ from '../components/FAQ'
import Footer from '../components/Footer'

import '../App.css'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <Spike />
      <Merch />
      <Jungle />
      <Features />
      <Roadmap />
      <Rarity />
      <Partners />
      <Team />
      <Subscribe />
      <FAQ />
      <Footer />
    </>
  )
}

export default Home
