import React from 'react'

import title from "../../images/partners.png"
import woke from "../../images/woke.png"
import liisa from "../../images/liisa.png"
import swagga from "../../images/swagga.png"

import {PartnersHome, PartnersContainer, PartnersTitle, PartnersImageTitle, PartnersMember, PartnersPhoto, PartnersExp, PartnersH1, PartnersH2, Photo} from './PartnersElements'

function Partners() {
  return (
        <PartnersHome id='Partners'>
            <PartnersContainer>
                <PartnersTitle>
                    <PartnersImageTitle src={title} type='image/png' />
                </PartnersTitle>
                <PartnersMember>
                    <PartnersPhoto>
                        <a href='https://wokecustoms.com'><Photo src={woke} type='image/png' /></a>
                    </PartnersPhoto>
                    <PartnersExp>
                        <PartnersH1>WOKECUSTOMS</PartnersH1>
                        <PartnersH2>WOKECUSTOMS is a brand that specializes in creating custom sneakers.</PartnersH2>
                        <PartnersH2>They pride themselves on their attention to detail and use of high-quality materials to bring their customers unique and stylish products.</PartnersH2>
                        <PartnersH2>In addition to their customization services, WOKECUSTOMS also offers a range of ready-made sneakers and apparel.</PartnersH2>
                        <PartnersH2>The brand has a strong focus on creativity and innovation.</PartnersH2>
                    </PartnersExp>
                </PartnersMember>
                <PartnersMember>
                    <PartnersPhoto>
                        <a href='https://liisa.io'><Photo src={liisa} type='image/png' /></a>
                    </PartnersPhoto>
                    <PartnersExp>
                        <PartnersH1>LIISA</PartnersH1>
                        <PartnersH2>An NFT analytics platform that uses AI to help you make better & faster decisions.</PartnersH2>
                        <PartnersH2>Besides quantitative data (evolution of price, sales volume, marketplace listings) you’ll get info about qualitative data like the community behind a project, the utility, the team/founders, social media presence, engagement, and growth.</PartnersH2>
                        <PartnersH2>Liisa is a startup company based in Lisbon (Portugal).</PartnersH2>
                    </PartnersExp>
                </PartnersMember>
                <PartnersMember>
                    <PartnersPhoto>
                        <a href='https://swagga.io'><Photo src={swagga} type='image/png' /></a>
                    </PartnersPhoto>
                    <PartnersExp>
                        <PartnersH1>SWAGGA</PartnersH1>
                        <PartnersH2>The most important metaverse in Japan, and one of the Asian pioneers! SWAGGA is born to transform and pioneer the way we socialize, interact and entertain in virtual world.</PartnersH2>
                        <PartnersH2>They are an immersive phygital metaverse that blends Fashion, Entertainment, E-Commerce and Social Network with the ambition of incentivizing everyone to monetize likes.</PartnersH2>
                        <PartnersH2>SWAGGA thrive to create an entertaining environment for fashion enthusiasts, further progress towards an interactive and interoperable metaverse.</PartnersH2>
                    </PartnersExp>
                </PartnersMember>
        </PartnersContainer>
      </PartnersHome>
    );
  }
  
  export default Partners;
