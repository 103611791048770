// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { WLUserVerified, WLUser, NFTUser } = initSchema(schema);

export {
  WLUserVerified,
  WLUser,
  NFTUser
};