import React, {useState, useEffect} from 'react'

import { IconContext } from 'react-icons/lib'
import { FaInstagram, FaDiscord, FaXTwitter, FaTiktok, FaBars} from 'react-icons/fa6'
import { SocialIconLink } from '../Footer/FooterElements'
import { ImageLogo, Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavLinks, NavItem, NavBtn, NavSocial } from './NavbarElements'

import logo from "../../images/bs-logo.png";
import logofl from "../../images/fl-logo-alone.png";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {window.addEventListener('scroll', changeNav)}, [])


  const GoFireLight = () => {window.open('https://firelight-ent.com');}

  return (
    <>
    <IconContext.Provider value={{ color: '#fff'}}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo>
              <ImageLogo src={logo} type='image/png' />
              <ImageLogo onClick={GoFireLight} src={logofl} type='image/png' />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to='features' smooth={true} duration={500} spy={true} exact='true' offset={-80} >
                  FEATURES
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='roadmap' smooth={true} duration={500} spy={true} exact='true' offset={-80} >
                  ROADMAP
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='team' smooth={true} duration={500} spy={true} exact='true' offset={-80} >
                  TEAM
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='rarity' smooth={true} duration={500} spy={true} exact='true' offset={-80} > 
                  TRAIT RARITY
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='FAQ' smooth={true} duration={500} spy={true} exact='true' offset={-80} > 
                  FAQ's
              </NavLinks>
            </NavItem>
          </NavMenu>
          <NavSocial>
            <SocialIconLink href='https://discord.gg/JZ3QJNf2zu' target='_blank' arial-label='Discord'>
              <FaDiscord />
            </SocialIconLink>
            <SocialIconLink href='https://www.instagram.com/brainlesspikes/' target='_blank' arial-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink href='https://twitter.com/BrainlesSpikes' target='_blank' arial-label='Twitter'>
              <FaXTwitter />
            </SocialIconLink>
            <SocialIconLink href='https://www.tiktok.com/@brainlesspikes' target='_blank' arial-label='TikTok'>
              <FaTiktok />
            </SocialIconLink>
          </NavSocial>
          <NavBtn>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
    </>
  )
}

export default Navbar
