import styled from 'styled-components'

export const FAQHome = styled.div`
  display:flex;
  justify-content: center;
`
export const FAQContainer = styled.div`
  display: flex;
  max-width: 1920px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding-bottom: 30px;
`
export const FAQTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 40px 20px 40px 20px;
`
export const FaqDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;

  @media screen and (max-width: 767px) {
    align-items: flex-start;
    width: 100%;
  }  
`
export const FAQImageTitle = styled.img`
  height:120px;
  width: auto;
  user-select: none;
`

export const FaqQuestionH1 = styled.div`
  width: 50%;
  font-family: "LemonMilk-regular";
  font-size: 14px;
  color: #6aacd8;
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 10px;
  text-align: right;

  @media screen and (max-width: 767px) {
    text-align: Left;
    width: 100%;
  }  

`
export const FaqAnswerH2 = styled.div`
  width: 50%;  
  font-family: "LemonMilk-light";
  font-size: 12px;
  color: white;
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 10px;
  text-align: right;

  @media screen and (max-width: 767px) {
    text-align: Left;
    width: 100%;
  }  
`