import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

export const ImageLogo = styled.img`
  height: 60px;
  margin-top: 10px;
  width: auto; 
  user-select: none;
`
export const ImageConnect = styled.img`
  height: 90px;
  width: auto;
  user-select: none;
`

export const Nav = styled.nav`
  background: ${({scrollNav}) => (scrollNav ? '#000' : 'transparent')};
  height: 80px;
  margin-top: -80px;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top:0;
  z-index:10;

  @media screen and (max-width: 1224px) {
    transition: 0.8s all ease;
  }
`
export const NavSocial = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 24px;
  width: 100%;
  max-width: 180px;

  @media screen and (max-width: 1224px) {
    display: none;
  }
`
export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1920px;
`
export const NavLogo = styled(LinkR) `
  justify-self: left;
  cursor: pointer;
  display: flex;
`
export const MobileIcon = styled.div`
  display: none;
  height: 32px;
  
  @media screen and (max-width: 1920px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`
export const NavMenu = styled.ul`
  display: none;
  justify-content: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 1224px) {
    display: none;
  }
`
export const NavItem = styled.li`
  height: 80px;
`
export const NavLinks = styled(LinkS)`
  color: white;
  display: flex;
  align-items: center;
  font-family: "Dumbnerd";
  font-size: 25px;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #3B82F6
  }
`
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  max-width: 250px;
  padding-right: 300px;
  
  @media screen and (max-width: 1224px) {
    display: none;
  }
`
export const NavBtnLink = styled(LinkR)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 80px;
`
