import React from 'react'
import { useMediaQuery } from 'react-responsive'

import title from '../../images/features.png'
import {FeaturesHome, FeaturesContainer, FeatureTitle, FeatureImageTitle, FeatureDescription, FeaturesCard, FeatureLeft, FeatureRight, FeaturesTextRightH1, FeaturesTextLeftH1, FeaturesTextLeftH2, FeaturesTextRightH2} from './FeaturesElements'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1224 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1224 })
  return isMobile ? children : null
}

const Feature = () => (
<div id='features'>
    <Desktop>
        <FeaturesHome>
            <FeaturesContainer>
              <FeatureTitle>
                 <FeatureImageTitle src={title} type='image/png' />
              </FeatureTitle>
              <FeatureDescription>
                <FeaturesCard>
                  <FeatureLeft>
                    <FeaturesTextLeftH1 data-aos='fade-right'>2.000 SPIKES</FeaturesTextLeftH1>
                    <FeaturesTextLeftH2 data-aos='fade-right'>2.000 SUPER EXCLUSIVE NFT WHICH GRANT THEIR HOLDERS WITH SUSTAINABLE UTILITIES AND REWARDS. PRODUCED BY FIRELIGHT ENTERTAINMENT.</FeaturesTextLeftH2>
                    <FeaturesTextLeftH1 data-aos='fade-right'>NEXT LEVEL QUALITY</FeaturesTextLeftH1>
                    <FeaturesTextLeftH2 data-aos='fade-right'>THE COLLECTION HAS A 3D ANIMATION FILM QUALITY. PART OF OUR NFT ARE 3D ANIMATIONS, WHICH MAKES BRAINLESS SPIKES A UNIQUE PROJECT.</FeaturesTextLeftH2>
                    <FeaturesTextLeftH1 data-aos='fade-right'>ANIMATION SERIES</FeaturesTextLeftH1>
                    <FeaturesTextLeftH2 data-aos='fade-right'>WE ARE THE FIRST GLOBAL ANIMATION SERIES BORN IN WEB3. OUR COMMUNITY CREATES THE PRODUCTION WITH OUR ARTISTS. THEIR NFT'S AVATARS ARE THE SERIES CHARACTERS.</FeaturesTextLeftH2>
                 </FeatureLeft>
                 <FeatureRight>
                    <FeaturesTextRightH1 data-aos='fade-left'>REVENUE FOR OUR HOLDERS</FeaturesTextRightH1>
                    <FeaturesTextRightH2 data-aos='fade-left'>OUR HOLDERS WILL BE REWARDED WITH THE 50% INCOME OF THE SERIES SALE TO DIGITAL PLATFORMS (NETFLIX, DISNEY, HBO, ETC). 50% INCOME OF EACH FIRELIGHT ENTERTAINMENT PROJECT WILL BE ALSO FOR OUR COMMUNITY.</FeaturesTextRightH2>
                    <FeaturesTextRightH1 data-aos='fade-left'>RARITY</FeaturesTextRightH1>
                    <FeaturesTextRightH2 data-aos='fade-left'>OUR NFTS ARE MADE OF FIVE RARITY LEVELS: SWORDS, SOLDIER, EPIC, LEGENDARY AND GIFT. EACH RARITY TYPE WILL GIVE OUR HOLDERS DIFFERENT REWARDS.</FeaturesTextRightH2>
                    <FeaturesTextRightH1 data-aos='fade-left'>GIFTS</FeaturesTextRightH1>
                    <FeaturesTextRightH2 data-aos='fade-left'>BRAINLESS SPIKES WILL GIVE AWAY TONS OF NFTS OF EXTREME RARITY. THEY WILL HAVE A POWERFUL UTILITY IN THE SECOND STAGE OF THE PROJECT.</FeaturesTextRightH2>
                 </FeatureRight>
                </FeaturesCard>
              </FeatureDescription>
            </FeaturesContainer>
          </FeaturesHome>
      </Desktop>
      <Mobile>
        <FeaturesHome id='features'>
            <FeaturesContainer>
              <FeatureTitle>
                 <FeatureImageTitle src={title} type='image/png' />
              </FeatureTitle>
              <FeatureDescription>
                <FeaturesCard>
                  <FeatureLeft>
                    <FeaturesTextLeftH1 data-aos='fade-right'>2.000 SPIKES</FeaturesTextLeftH1>
                    <FeaturesTextLeftH2 data-aos='fade-right'>2.000 SUPER EXCLUSIVE NFT WHICH GRANT THEIR HOLDERS WITH SUSTAINABLE UTILITIES AND REWARDS. PRODUCED BY FIRELIGHT ENTERTAINMENT PRODUCTION COMPANY.</FeaturesTextLeftH2>
                    <FeaturesTextLeftH1 data-aos='fade-right'>NEXT LEVEL QUALITY</FeaturesTextLeftH1>
                    <FeaturesTextLeftH2 data-aos='fade-right'>THE COLLECTION HAS A 3D ANIMATION FILM QUALITY. PART OF OUR NFT ARE 3D ANIMATIONS, WHICH MAKES BRAINLESS SPIKES A UNIQUE PROJECT.</FeaturesTextLeftH2>
                    <FeaturesTextLeftH1 data-aos='fade-right'>ANIMATION SERIES</FeaturesTextLeftH1>
                    <FeaturesTextLeftH2 data-aos='fade-right'>WE ARE THE FIRST GLOBAL ANIMATION SERIES BORN IN WEB3. OUR COMMUNITY CREATES THE PRODUCTION WITH OUR ARTISTS. THEIR NFT'S AVATARS ARE THE SERIES CHARACTERS.</FeaturesTextLeftH2>
                    <FeaturesTextLeftH1 data-aos='fade-right'>REVENUE FOR OUR HOLDERS</FeaturesTextLeftH1>
                    <FeaturesTextLeftH2 data-aos='fade-right'>OUR HOLDERS WILL BE REWARDED WITH THE 50% INCOME OF THE SERIES SALE TO DIGITAL PLATFORMS (NETFLIX, DISNEY, HBO, ETC). 50% INCOME OF EACH FIRELIGHT ENTERTAINMENT AUDIOVISUAL AND NFT PROJECT WILL BE ALSO FOR OUR COMMUNITY.</FeaturesTextLeftH2>
                    <FeaturesTextLeftH1 data-aos='fade-right'>RARITY</FeaturesTextLeftH1>
                    <FeaturesTextLeftH2 data-aos='fade-right'>OUR NFTS ARE MADE OF FIVE RARITY LEVELS: SWORDS, SOLDIER, EPIC, LEGENDARY AND GIFT. EACH RARITY TYPE WILL GIVE OUR HOLDERS DIFFERENT REWARDS.</FeaturesTextLeftH2>
                    <FeaturesTextLeftH1 data-aos='fade-right'>GIFTS</FeaturesTextLeftH1>
                    <FeaturesTextLeftH2 data-aos='fade-right'>BRAINLESS SPIKES WILL GIVE AWAY TONS OF NFTS OF EXTREME RARITY. THEY WILL HAVE A POWERFUL UTILITY IN THE SECOND STAGE OF THE PROJECT.</FeaturesTextLeftH2>
                  </FeatureLeft>
                </FeaturesCard>
              </FeatureDescription>
            </FeaturesContainer>
          </FeaturesHome>
      </Mobile>
  </div>
  )

export default Feature