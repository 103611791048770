import styled from 'styled-components'

import legendarybox from "../../images/legendary_box.png";
import soldierbox from "../../images/soldier_box.png";
import epicbox from "../../images/epic_box.png";
import giftbox from "../../images/gift_box.png";

export const RarityHome = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
`
export const RarityContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  flex-direction: column;
  align-items: center;
  
`
export const RarityTitle = styled.div`
  display: flex;
  position: relative;
  max-width: 500px;
  justify-content: center;
  padding: 80px 20px 40px 20px;
`
export const RarityImageTitle = styled.img`
  width: 100%;
  user-select: none;
`
export const RaritySubTitle = styled.div`
  display: flex;
  position: relative;
  max-width: 500px;
  flex-direction: column;
  padding: 0px 20px 40px 20px;
`
export const RarityTraits = styled.div`
  display: flex;
  width: 100%;
  margin-too: 20px;
`
export const Soldier = styled.div`
  width: 100%;
  display: flex;
`
export const SoldierBox = styled.div`
  background-image: url(${soldierbox});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center top;
`
export const SoldierNFT = styled.div`
  display: flex;
  padding-top: 5px;
  justify-content: center;
`
export const Epic = styled.div`
  width: 100%;
  display: flex;
`
export const EpicBox = styled.div`
  background-image: url(${epicbox});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center top;
`
export const EpicNFT = styled.div`
  display: flex;
  padding-top: 5px;
  justify-content: center;
`
export const Legendary = styled.div`
  width: 100%;
  display: flex;
`
export const LegendaryBox = styled.div`
  background-image: url(${legendarybox});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center top;
`
export const LegendaryNFT = styled.div`
  display: flex;
  justify-content: center;
`
export const Gift = styled.div`
  width: 100%;
  display: flex;
`
export const GiftBox = styled.div`
  background-image: url(${giftbox});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
`
export const GiftNFT = styled.div`
  display: flex;
  widht: 100%;
  justify-content: center;
`
export const RarityH1 = styled.h1`
  font-family: "LemonMilk-Light";
  font-size: 12px;
  color: white;
  text-align: center;
`
export const RarityH2 = styled.h2`
  font-family: "LemonMilk-Light";
  font-size: 12px;
  color: #78b0dd;
  text-align: center;
  margin-bottom: 10px;
`
export const SoldierNFTh1 = styled.h1`
  font-family: "LemonMilk-BoldItalic";
  font-size: 12px;
  color: #8aff9a;
  text-align: center;
  margin-bottom: 10px;
`
export const EpicNFTh1 = styled.h1`
  font-family: "LemonMilk-BoldItalic";
  font-size: 14px;
  color: #7054df;
  text-align: center;
  margin-bottom: 10px;
`
export const LegendaryNFTh1 = styled.h1`
  font-family: "LemonMilk-BoldItalic";
  font-size: 12px;
  color: #6aacd8;
  text-align: center;
  margin-bottom: 10px;
`
export const GiftNFTh1 = styled.h1`
  font-family: "LemonMilk-BoldItalic";
  font-size: 14px;
  color: #db903a;
  text-align: center;
  margin-bottom: 10px;
`
export const NFTh2 = styled.div`
  font-family: "LemonMilk-Light";
  font-size: 10px;
  color: white;
  text-align: left;
  Padding: 0px 5px 5spx 5px;
  margin-bottom: 20px;
  margin-left: 50px;
  margin-right: 50px;
  
`
export const ImgNFT = styled.img`
  padding: 15px 50px 10px 50px;
  widht: 100%;
  user-select: none;
`

