import styled from 'styled-components'

export const RoadmapHome = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
`
export const RoadmapContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 0px 20px;
 `
 export const RoadmapTitle = styled.div`
 display: flex;
 max-width: 500px;
 justify-content: center;
 padding: 0px 20px 0px 20px;
`
export const RoadmapImageTitle = styled.img`
 width: 100%;
 padding: 40px 0px 40px 0px;
 user-select: none;
`
 export const RoadmapStep = styled.div`
 display: flex;
 position: relative;
 width: 100%;
 max-width: 1150px;
 align-content: center;
 padding-bottom: 40px;
`
export const RoadmapLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  border-right: 1px solid white;
`
export const RoadmapRight = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`
export const RoadmapTextLeftH1 = styled.div`
  font-family: "LemonMilk-BoldItalic";
  font-size: 15px;
  text-align: right;
  color: white;
  margin-bottom: 5px;
  padding-right: 40px;
`
export const RoadmapTextLeftH2 = styled.div`
  font-family: "LemonMilk-Bold";
  font-size: 20px;
  text-align: right;
  color: #02fe94;
  margin-bottom: 5px;
  padding-right: 40px;
`
export const RoadmapTextLeftH3 = styled.div`
  font-family: "LemonMilk-Light";
  font-size: 12px;
  text-align: right;
  color: white;
  margin-bottom: 5px;
  padding-right: 40px;
` 
export const RoadmapTextRightH1 = styled.div`
  font-family: "LemonMilk-BoldItalic";
  font-size: 15px;
  color: white;
  margin-bottom: 5px;
  padding-left: 40px;
`
export const RoadmapTextRightH2 = styled.div`
  font-family: "LemonMilk-Bold";
  font-size: 20px;
  color: #02fe94;
  margin-bottom: 5px;
  padding-left: 40px;
`
export const RoadmapTextRightH3 = styled.div`
  font-family: "LemonMilk-Light";
  font-size: 12px;
  color: white;
  margin-bottom: 5px;
  padding-left: 40px;
`
export const RoadmapNroLeft = styled.img`
  height: 120px;
  width: auto;
  padding-right: 100px;
  user-select: none;

  @media screen and (max-width: 768px) {
    padding-right: 40px;
  }
`
export const RoadmapNroRight = styled.img`
  height:120px;
  width: auto; 
  padding-left: 100px;
  user-select: none;

  @media screen and (max-width: 768px) {
    padding-left: 40px;
  }
`