import styled from 'styled-components'

export const PartnersHome = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
`
export const PartnersContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 60px 0;
`
export const PartnersTitle = styled.div`
  display: flex;
  position: relative;
  max-width: 500px;
  justify-content: center;
  padding: 0 0 60px 0;
`
export const PartnersImageTitle = styled.img`
  width: 100%;
  user-select: none;
`
export const PartnersMember = styled.div`
  display: flex;
  width: 55%;
  justify-content: center;
  padding: 10px 20px;
  
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const PartnersPhoto = styled.div`
  display: flex;
  height: auto;
  width: 150px;
  flex-direction: column;
`
export const PartnersExp = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 50px;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding-left: 10px;
  }
`
export const PartnersH1 = styled.div`
  font-family: "LemonMilk-BoldItalic";
  font-size: 14px;
  color:#00FF95;
  margin-bottom: 5px;

  @media screen and (max-width: 678px) {
    font-size: 12px;
  }  
`
export const PartnersH2 = styled.div`
  font-family: "LemonMilk-Light";
  font-size: 10px;
  line-height : 13px;
  color: white;
`
export const PartnersH3 = styled.div`
  font-family: "LemonMilk-Bold";
  font-size: 10px;
  line-height : 13px;
  color: white;
`
export const PartnersH4 = styled.div`
  font-family: "LemonMilk-Bold";
  font-size: 10px;
  line-height : 13px;
  color: white;
  margin-top: 20px;
`
export const Photo = styled.img`
  height: 150px;
  width: auto;
  user-select: none;
`
