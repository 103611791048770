import React from "react";

import { FaTelegramPlane } from 'react-icons/fa';
import { SubscribeHome, SubscribeContainer, SubscribeDescription, SubscribeField, SubscribeIconLink, SubscribeText, SuscribeBox, SubscribeNotify} from "./SubscribeElements";

import { DataStore } from '@aws-amplify/datastore';
import { NFTUser } from '../../models';

async function handleKeyPress(event) {

  if (event.key === 'Enter'){

    userRegistration()

  }

}

async function userRegistration() {

  let inputValue = document.getElementById("email").value;

  let expr = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

	if(!expr.exec(inputValue)){

    document.getElementById('notify').innerHTML = "Invalid Email Address";
  
  } else {
  
    await DataStore.save(new NFTUser({
        email: inputValue,
      })
    )

    document.getElementById('email_top').value = "";

    document.getElementById('notify').innerHTML = "¡¡¡ Thanks for subscribing !!!";

  }

};

function Subscribe() {
  return (
    <SubscribeHome id="Subscribe">
      <SubscribeContainer>
        <SubscribeDescription>
          <SubscribeText>SUBSCRIBE TO OUR EXCLUSIVE LIST AND BE THE FIRST TO KNOW ABOUT UPCOMING NEWS</SubscribeText>
          <SuscribeBox>
            <SubscribeField id="email" placeholder="Enter your email address" onKeyPress={handleKeyPress}></SubscribeField><SubscribeIconLink><FaTelegramPlane onClick={userRegistration} /></SubscribeIconLink>
          </SuscribeBox>
          <SubscribeNotify id="notify"></SubscribeNotify>
        </SubscribeDescription>
      </SubscribeContainer>
    </SubscribeHome>
  );
}

export default Subscribe;