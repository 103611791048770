import styled from 'styled-components'

export const MerchHome = styled.div`
  display:flex;
  justify-content: center;
  position: relative;
  align-items: center;
`
export const MerchContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 0px 20px;
 `

export const MerchDescription = styled.div`
  display: flex;
  align-items: center;
  padding: 80px 0px 40px 0px;

  @media screen and (max-width: 1224px) {
    flex-direction: column;
  }
 `
export const MerchLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 100px;

  @media screen and (max-width: 1224px) {
    padding-top: 15px;
    margin-left: 0px;
    margin-top: 20px;
  }
`
export const MerchKing = styled.div`
  display: flex;
  width: 110%;
  align-items: flex-start;
  justify-content: space-around;

  @media screen and (max-width: 1224px) {
    padding-top: 10px;
  }
`
export const MerchRight = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const MerchTopTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
`
export const MerchTitle = styled.div`
  display: flex;
  max-width: 500px;
  justify-content: center;
  padding-bottom: 10px;
`
export const MerchImageTitle = styled.img`
  width: 100%;
  user-select: none;
`
export const MerchTextH1 = styled.div`
  font-family: "LemonMilk-Bold";
  font-size: 40px;
  text-align: center;
  line-height : 60px;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 35px;
    line-height : 50px;
  }
`
export const MerchTextH2 = styled.div`
  font-family: "LemonMilk-Bold";
  font-size: 30px;
  line-height : 40px;
  text-align: center;
  color:#00FF95;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height : 35px;
  }
`

export const MerchTextH3 = styled.div`
  font-family: "LemonMilk-Light";
  font-size: 14px;
  text-align: center;
  color: white;
  line-height : 18px;
`