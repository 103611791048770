import styled from 'styled-components'

export const JungleHome = styled.div`
  display:flex;
  justify-content: center;
  position: relative;
  align-items: center;
`
export const JungleContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 0px 20px;
 `

export const JungleDescription = styled.div`
  display: flex;
  align-items: center;
  padding: 80px 0;

  @media screen and (max-width: 1224px) {
    flex-direction: column;
  }
 `
export const JungleLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 100px;

  @media screen and (max-width: 1224px) {
    padding-top: 15px;
    margin-right: 0px;
  }
`
export const JungleKing = styled.div`
  display: flex;
  width: 110%;
  align-items: flex-start;
  justify-content: space-around;

  @media screen and (max-width: 1224px) {
    padding-top: 10px;
  }
`
export const JungleRight = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const JungleTopTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
`
export const JungleTitle = styled.div`
  display: flex;
  max-width: 500px;
  justify-content: center;
  padding-bottom: 10px;
`
export const JungleImageTitle = styled.img`
  width: 100%;
  user-select: none;
`
export const JungleTextH1 = styled.div`
  font-family: "LemonMilk-Bold";
  font-size: 50px;
  text-align: center;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 35px;
  }
`
export const JungleTextH2 = styled.div`
  font-family: "LemonMilk-Bold";
  font-size: 30px;
  text-align: right;
  color:white;
  margin-left: 9px;
  margin-bottom: 7px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`

export const JungleTextH3 = styled.div`
  font-family: "LemonMilk-Light";
  font-size: 16px;
  text-align: center;
  color: white;
  line-height : 18px;
`