import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive'

import { FaTelegramPlane } from 'react-icons/fa';
import logo from "../../images/logo.png";
import spray from "../../images/spray.png";
import btntwitter from "../../images/twitter_button.png";
import btndiscord from "../../images/discord_button.png";
import btnwiki from "../../images/wiki_button.png";
import x2y2 from "../../images/x2y2.png";
import x2y2over from "../../images/x2y2-over.png";
import rarible from "../../images/rarible.png";
import raribleeover from "../../images/rarible-over.png";
import looksrare from "../../images/looksrare.png";
import looksrareover from "../../images/looksrare-over.png";

import { SpikeHome, SpikeContainer, SpikeOne, SpikeLeft, SocialButtons, SpikeRight, Logo, Spray, SocialBtn, BtnBox, SuscribeBox, SubscribeField, SubscribeIconLink, BrochaBox, Marketplace, MarketplaceTextH1} from './SpikeElements'

import { DataStore } from '@aws-amplify/datastore';
import { NFTUser } from '../../models';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1224 })
    return isDesktop ? children : null
}
  
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1224 })
    return isMobile ? children : null
}

async function handleKeyPress(event) {

    if (event.key === 'Enter'){
  
      userRegistration()
  
    }
  
  }
  
async function userRegistration() {

    let inputValue = document.getElementById("email_top").value;
  
    let expr = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  
        if(!expr.exec(inputValue)){
  
            document.getElementById('email_top').value = "";
            document.getElementById('email_top').placeholder = "Invalid Email Address";
            
        } else {
    
        await DataStore.save(
            new NFTUser({
            email: inputValue,
            })
        )
      
        document.getElementById('email_top').value = "";
        document.getElementById('email_top').placeholder = "¡¡¡ Thanks for subscribing !!!";
  
        }
  
};

export default function Feature() {

const [looksRareSrc, setLooksRareSrc] = useState(looksrare);
const [raribleSrc, setRaribleSrc] = useState(rarible);
const [x2y2Src, setx2y2Src] = useState(x2y2);

const handleLooksRareEnter = () => {
    setLooksRareSrc(looksrareover); 
};

const handleLooksRareLeave = () => {
    setLooksRareSrc(looksrare);
};

const handleRaribleEnter = () => {
    setRaribleSrc(raribleeover); 
};

const handleRaribleLeave = () => {
    setRaribleSrc(rarible);
};

const handleX2Y2Enter = () => {
    setx2y2Src(x2y2over); 
};

const handleX2Y2Leave = () => {
    setx2y2Src(x2y2);
};

const GoX2Y2 = () => {window.open('https://x2y2.io/collection/brainless-spikes-originals/items');}
const GoLooksRare = () => {window.open('https://looksrare.org/collections/0xc8569860fC503d0C590a4e17aB6AB4636c93764e');}
const GoRarible = () => {window.open('https://rarible.com/brainless-spikes-originals/items');}

return (
    <div id='spike'>
        <Desktop>
            <SpikeHome >
              <SpikeContainer>
                <SpikeOne>
                  <SpikeLeft>
                      <Logo src={logo} type='image/png' />
                      <BrochaBox>
                        <MarketplaceTextH1>BUY ON</MarketplaceTextH1>
                        <Marketplace src={x2y2Src} type='image/png' onMouseEnter={handleX2Y2Enter}  onMouseLeave={handleX2Y2Leave} onClick={GoX2Y2}/>
                        <Marketplace src={looksRareSrc} type='image/png' onMouseEnter={handleLooksRareEnter}  onMouseLeave={handleLooksRareLeave} onClick={GoLooksRare} />
                        <Marketplace src={raribleSrc} type='image/png' onMouseEnter={handleRaribleEnter}  onMouseLeave={handleRaribleLeave} onClick={GoRarible} />
                      </BrochaBox>
                      <SocialButtons>
                            <BtnBox>
                                <a href='https://twitter.com/BrainlesSpikes'><SocialBtn src={btntwitter} type='image/png' /></a>
                            </BtnBox>
                            <BtnBox>
                                <a href='https://discord.gg/JZ3QJNf2zu'><SocialBtn src={btndiscord} type='image/png' /></a>
                            </BtnBox>
                            <BtnBox>
                                <a href='https://brainless-spikes.gitbook.io/brainless-spikes-wiki'><SocialBtn src={btnwiki} type='image/png' /></a>
                            </BtnBox>
                      </SocialButtons>
                      <SuscribeBox>
                            <SubscribeField id="email_top" placeholder="SUBSCRIBE TO OUR EXCLUSIVE EMAIL LIST" onKeyPress={handleKeyPress}></SubscribeField><SubscribeIconLink><FaTelegramPlane onClick={userRegistration} /></SubscribeIconLink>
                      </SuscribeBox>
                  </SpikeLeft>
                  <SpikeRight>
                      <Spray src={spray}  type='image/png'/>
                  </SpikeRight>
                </SpikeOne>
              </SpikeContainer>
            </SpikeHome>
        </Desktop>
        <Mobile>
            <SpikeHome >
              <SpikeContainer>
                <SpikeOne>
                  <SpikeLeft>
                      <Logo src={logo} type='image/png' />
                      <BrochaBox>
                        <MarketplaceTextH1>BUY ON</MarketplaceTextH1>
                        <Marketplace src={x2y2Src} type='image/png' onMouseEnter={handleX2Y2Enter}  onMouseLeave={handleX2Y2Leave} onClick={GoX2Y2}/>
                        <Marketplace src={looksRareSrc} type='image/png' onMouseEnter={handleLooksRareEnter}  onMouseLeave={handleLooksRareLeave} onClick={GoLooksRare} />
                        <Marketplace src={raribleSrc} type='image/png' onMouseEnter={handleRaribleEnter}  onMouseLeave={handleRaribleLeave} onClick={GoRarible} />
                      </BrochaBox>
                      <SocialButtons>
                            <BtnBox>
                                <a href='https://twitter.com/BrainlesSpikes'><SocialBtn src={btntwitter} type='image/png' /></a>
                            </BtnBox>
                            <BtnBox>
                                <a href='https://discord.gg/JZ3QJNf2zu'><SocialBtn src={btndiscord} type='image/png' /></a>
                            </BtnBox>
                            <BtnBox>
                                <a href='https://brainless-spikes.gitbook.io/brainless-spikes-wiki'><SocialBtn src={btnwiki} type='image/png' /></a>
                            </BtnBox>
                      </SocialButtons>
                      <SuscribeBox>
                            <SubscribeField id="email_top" placeholder="SUBSCRIBE TO OUR EXCLUSIVE EMAIL LIST" onKeyPress={handleKeyPress}></SubscribeField><SubscribeIconLink><FaTelegramPlane onClick={userRegistration} /></SubscribeIconLink>
                      </SuscribeBox>
                  </SpikeLeft>
                  <SpikeRight>
                      <Spray src={spray} type='image/png' />
                  </SpikeRight>
                  </SpikeOne>
              </SpikeContainer>
            </SpikeHome>
        </Mobile>
    </div>
)}
