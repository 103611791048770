import React from 'react'

import title from "../../images/team-firelight.png"
import marc from "../../images/marc.png"
import yensen from "../../images/yensen.png"
import charles from "../../images/charles.png"
import bernie from "../../images/bernie.png"
import valeria from "../../images/valeria.png"
import gonzalo from "../../images/gonzalo.png"

import {TeamHome, TeamContainer, TeamTitle, TeamImageTitle, TeamMember, TeamPhoto, TeamExp, TeamH1, TeamH2, TeamH4, Photo} from './TeamElements'

function Team() {
  return (
        <TeamHome id='team'>
            <TeamContainer>
                <TeamTitle>
                    <a href='https://firelight-ent.com'><TeamImageTitle src={title} type='image/png' /></a>
                </TeamTitle>
                <TeamMember>
                    <TeamPhoto>
                        <Photo src={yensen} type='image/png' />
                    </TeamPhoto>
                    <TeamExp>
                        <TeamH1>CO-CEO AND 3D ANIMATOR</TeamH1>
                        <TeamH2>3D ANIMATOR SPECIALIST AND ADOBE'S SCHOLARSHIP WINNER.</TeamH2>
                        <TeamH2>PASSIONATE ABOUT 3D ANIMATIONS AND DEVELOPING INTERACTIVE AND MULTIMEDIA AUDIOVISUAL PRODUCTS.</TeamH2>
                        <TeamH2>I'VE BEEN WORKING FOR MANY WORLDWIDE STUDIOS, BEING PART OF HUGE PRODUCTIONS LIKE</TeamH2>
                        <TeamH2>'SPIDER-MAN: NO WAY HOME' (MARVEL) AND K/DA (RIOT GAMES).</TeamH2>
                        <TeamH4>YENSEN ORLANDO QUINTERO ----</TeamH4>
                    </TeamExp>
                </TeamMember>
                <TeamMember>
                    <TeamPhoto>
                        <Photo src={marc} type='image/png' />
                    </TeamPhoto>
                    <TeamExp>
                        <TeamH1>CO-CEO AND 3D CHARACTER ARTIST</TeamH1>
                        <TeamH2>3D CHARACTER VISUAL DEVELOPMENT ARTIST, 3D CHARACTER MODELER AND SHADING ARTIST.</TeamH2>
                        <TeamH2>HE HAD HIS ADVENTURES IN FINLAND AS CO-FOUNDER OF A VIDEO GAME STARTUP AND LATER FOCUSED ON NATIONAL AND INTERNATIONAL AUDIOVISUAL PROJECTS FOR VARIOUS STUDIOS SUCH AS ANIMALOGIC AND NETFLIX.</TeamH2>
                        <TeamH2>RIGHT NOW HE IS FOCUSING MORE ON THE WORLD OF ARTISTIC DIRECTION AND COORDINATION ON DIFFERENT PROJECTS.</TeamH2>
                        <TeamH4>MARC MARTÍN ----</TeamH4>
                    </TeamExp>
                </TeamMember>
                <TeamMember>
                    <TeamPhoto>
                        <Photo src={charles} type='image/png' />
                    </TeamPhoto>
                    <TeamExp>
                        <TeamH1>SENIOR ANIMATOR</TeamH1>
                        <TeamH2>BASED IN THE BEAUTIFUL VANCOUVER, CANADA. CURRENTLY, HE IS WORKING AT BLIZZARD ENTERTAINMENT ON OVERWATCH 2.</TeamH2>
                        <TeamH2>CHARLES HAS BEEN IN THE ANIMATION INDUSTRY FOR OVER 10 YEARS AND IN THAT TIME HE HAS WORKED IN MULTIPLE TYPES OF PROJECTS SUCH AS FILM, GAMEPLAY AND CINEMATIC.</TeamH2>
                        <TeamH2>HE IS SUPER PASSIONATE IN ANYTHING ANIMATION AND IS GRATEFUL TO BE WITH A SUPER TALENTED TEAM ON BRAINLESS SPIKE. SINCE JUMPING ON BOARD IN 2021, CHARLES HAS BEEN HELPING OUT ON ANIMATING AND CREATING STORYTELLING MOMENTS ON BRAINLESS SPIKE.</TeamH2>
                        <TeamH4>CHARLES ICAY ----</TeamH4>
                    </TeamExp>
                </TeamMember>
                <TeamMember>
                    <TeamPhoto>
                        <Photo src={valeria} type='image/png' />
                    </TeamPhoto>
                    <TeamExp>
                        <TeamH1>LIGHTER AND COMPO ARTIST</TeamH1>
                        <TeamH2>3D ARTIST SPECIALIZED IN LIGHTING AND COMPOSITION, WITH THE AMBITION OF GIVING A UNIQUE SOUL AND PERSONALITY TO HER CHARACTERS AND ENVIRONMENTS.</TeamH2>
                        <TeamH2>CURRENTLY I'M A PART OF DIFFERENT PROJECTS ALL OVER THE WORLD, WORKING ON CINEMATICS FOR VIDEO GAMES, SERIES AND COMMERCIALS.</TeamH2>
                        <TeamH4>VALERIA HERRERA ----</TeamH4>
                    </TeamExp>
                </TeamMember>
                <TeamMember>
                    <TeamPhoto>
                        <Photo src={bernie} type='image/png' />
                    </TeamPhoto>
                    <TeamExp>
                        <TeamH1>EXECUTIVE PRODUCER</TeamH1>
                        <TeamH2>COMMUNICATION ENTHUSIAST WHO LOVES MEDIA WORLD. I STUDIED MY JOURNALISM DEGREE IN BARCELONA</TeamH2>
                        <TeamH2>WHILE TRAVELING AROUND 35 DIFFERENT COUNTRIES. I'VE BEEN INVOLVED IN SOCIAL MEDIA ENVIRONMENT</TeamH2>
                        <TeamH2>FOR SO LONG, CREATING COMMUNITIES FOR MANY INTERNATIONAL BRANDS AND TV SHOW. INTERACTION IS MY</TeamH2>
                        <TeamH2>NATURAL PASSION, MY PHILOSPHY: A GREAT EFFORT SPRINGS NATURALLY FROM A GREAT ATTITUDE.</TeamH2>
                        <TeamH4>BERNI LÓPEZ ----</TeamH4>
                    </TeamExp>
                </TeamMember>
                <TeamMember>
                    <TeamPhoto>
                        <Photo src={gonzalo} type='image/png' />
                    </TeamPhoto>
                    <TeamExp>
                        <TeamH1>BLOCKCHAIN DEVELOPER</TeamH1>
                        <TeamH2>MY RESUME WILL SAY THAT I HAVE A COMPUTER SCIENCE DEGREE AND ALSO THAT I AM A COMPUTER PROGRAMMING</TeamH2>
                        <TeamH2>TECHNICIAN, BUT I WILL SAY THAT I'M CONSTANTLY TRYING TO BUILD THE BEST POSSIBLE VERSION OF MYSELF.</TeamH2>
                        <TeamH2>PROUD TO BE PART OF THE GREATEST GLOBAL TECHNOLOGICAL REVOLUTION AFTER THE INTERNET.</TeamH2>
                        <TeamH4>GONZALO ALFONSO ----</TeamH4>
                    </TeamExp>
                </TeamMember>
        </TeamContainer>
      </TeamHome>
    );
  }
  
  export default Team;
