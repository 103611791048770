import React from "react"
import {BrowserRouter as Router, Switch, Route} from  'react-router-dom'
import $ from 'jquery'

import Home from './pages'

import './App.css'

function App() {

    $(window).scroll(function(){
      var barra = $(window).scrollTop();
      var posicion =  (barra * 0.05);
      var x = window.matchMedia('(max-width: 768px)');
      
      if (x.matches) { // If media query matches

        $('body').css({
          'background-position': 'left -' + posicion + 'px'
        });

      } else {

        $('body').css({
          'background-position': 'center -' + posicion + 'px'
        });
  
      }
  
    });

  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact />
      </Switch>
    </Router>
  );
}

export default App;