import React from 'react'
import { useMediaQuery } from 'react-responsive'

import sneakers from "../../images/sneakers.png"

import {MerchHome, MerchContainer, MerchTitle, MerchImageTitle, MerchDescription, MerchLeft, MerchKing, MerchRight, MerchTopTitle, MerchTextH1, MerchTextH2, MerchTextH3} from './MerchElements'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1224 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1224 })
  return isMobile ? children : null
}

const Merch = () => (
<div id='merch'>
    <Desktop>
        <MerchHome>
            <MerchContainer>
              <MerchDescription>
                  <MerchRight>
                      <MerchTopTitle>
                        <MerchTextH1>OFFICIAL SNEAKERS LINE</MerchTextH1>
                      </MerchTopTitle>
                      <MerchTitle>
                        <a href='https://wokecustoms.com/'><MerchTextH2>GET 20% DISCOUNT WITH "BRAINLESS" CODE</MerchTextH2></a>
                      </MerchTitle>
                      <MerchTextH3>At our company, we believe in showing our appreciation</MerchTextH3>
                      <MerchTextH3>for our community by giving back.</MerchTextH3>
                      <MerchTextH3>We have recently partnered with Wokecustoms, a company that</MerchTextH3>
                      <MerchTextH3>specializes in creating unique and personalized sneakers.</MerchTextH3>
                      <MerchTextH3>With the code “Brainless” you can get a 20% discount</MerchTextH3>
                      <MerchTextH3>on each sneakers design. Don't miss this awesome chance:</MerchTextH3>
                      <MerchTextH3>Wokecustoms ships around the world!</MerchTextH3>
                  </MerchRight>
                 <MerchLeft>
                    <MerchKing>
                      <a href='https://wokecustoms.com/'><MerchImageTitle src={sneakers} type='image/png' /></a>
                    </MerchKing>
                 </MerchLeft>
              </MerchDescription>
            </MerchContainer>
          </MerchHome>
      </Desktop>
      <Mobile>
        <MerchHome>
            <MerchContainer>
              <MerchDescription>
                 <MerchRight>
                      <MerchTopTitle>
                        <MerchTextH1>OFFICIAL SNEAKERS LINE</MerchTextH1>
                      </MerchTopTitle>
                      <MerchTitle>
                        <a href='https://wokecustoms.com/'><MerchTextH2>GET 20% DISCOUNT WITH "BRAINLESS" CODE</MerchTextH2></a>
                      </MerchTitle>
                      <MerchTextH3>At our company, we believe in showing our appreciation</MerchTextH3>
                      <MerchTextH3>for our community by giving back.</MerchTextH3>
                      <MerchTextH3>We have recently partnered with Wokecustoms, a company that</MerchTextH3>
                      <MerchTextH3>specializes in creating unique and personalized sneakers.</MerchTextH3>
                      <MerchTextH3>With the code “Brainless” you can get a 20% discount</MerchTextH3>
                      <MerchTextH3>on each sneakers design. Don't miss this awesome chance:</MerchTextH3>
                      <MerchTextH3>Wokecustoms ships around the world!</MerchTextH3>
                 </MerchRight>
                 <MerchLeft>
                      <MerchKing>
                        <a href='https://wokecustoms.com/'><MerchImageTitle src={sneakers} type='image/png' /></a>
                      </MerchKing>
                 </MerchLeft>
              </MerchDescription>
            </MerchContainer>
          </MerchHome>
      </Mobile>
</div>
  )

export default Merch