import styled from 'styled-components'

export const FeaturesHome = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
`
export const FeaturesContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 0px 20px;
 `
 export const FeatureTitle = styled.div`
  display: flex;
  position: relative;
  max-width: 500px;
  justify-content: center;
  padding: 40px 20px 40px 20px;

  @media screen and (max-width: 1224px) {
    padding: 0px 20px 20px 20px;
  }
`
export const FeatureImageTitle = styled.img`
  width: 100%;
  user-select: none;
`
export const FeatureDescription = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 80px;

  @media screen and (max-width: 1224px) {
    padding-left: 0px;
    padding-right: 0px;
  }
 `

export const FeatureLeft = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 50px 0px 04px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 1224px) {
    padding: 0px;
  }
`
export const FeatureRight = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 0px 0px 50px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  border-left: 1px solid white;
`
export const FeaturesCard = styled.div`
  background: black;
  display: flex;
  flex-direction: row;
  border-radius: 30px;
  height: 100%;
  padding: 80px;

  @media screen and (max-width: 1224px) {
    padding: 60px;
  }
`
export const FeaturesTextRightH1 = styled.div`
  font-family: "LemonMilk-BoldItalic";
  font-size: 20px;
  text-align: right;
  color:white;
  margin-bottom: 20px;
`
export const FeaturesTextRightH2 = styled.div`
  font-family: "LemonMilk-Light";
  font-size: 14px;
  text-align: right;
  color: white;
  margin-bottom: 20px;
`

export const FeaturesTextLeftH1 = styled.div`
  font-family: "LemonMilk-BoldItalic";
  font-size: 20px;
  text-align: left;
  color:white;
  margin-bottom: 20px;
`
export const FeaturesTextLeftH2 = styled.div`
  font-family: "LemonMilk-Light";
  font-size: 14px;
  text-align: left;
  color: white;
  margin-bottom: 20px;
`
