import React from 'react'
import { useMediaQuery } from 'react-responsive'

import title from "../../images/here.png";
import devil from "../../images/devil.png";

import {JungleHome, JungleContainer, JungleTitle, JungleImageTitle, JungleDescription, JungleLeft, JungleKing, JungleRight, JungleTopTitle, JungleTextH1, JungleTextH3} from './JungleElements'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1224 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1224 })
  return isMobile ? children : null
}

const Jungle = () => (
<div id='jungle'>
    <Desktop>
        <JungleHome>
            <JungleContainer>
              <JungleDescription>
                  <JungleLeft>
                      <JungleKing>
                          <JungleImageTitle src={devil} type='image/png' />
                      </JungleKing>
                 </JungleLeft>
                 <JungleRight>
                    <JungleTopTitle>
                        <JungleTextH1>GET YOUR PROFITS</JungleTextH1>
                    </JungleTopTitle>
                    <JungleTitle>
                      <a href='https://brainless-spikes.gitbook.io/brainless-spikes-wiki/overview/rewards'><JungleImageTitle src={title} type='image/png' /></a>
                    </JungleTitle>
                    <JungleTextH3>BRAINLESS SPIKES IS AN AWESOME APE BREED THAT WILL BLOW UP</JungleTextH3>
                    <JungleTextH3>THE NFT GLOBAL MARKET.</JungleTextH3>
                    <JungleTextH3>THE SPIKES ARE LIVING IN THE ETHEREUM BLOCKCHAIN ORGANIZED IN</JungleTextH3>
                    <JungleTextH3>A 2.000 EXCLUSIVE SUPPLY COLLECTION.</JungleTextH3>
                    <JungleTextH3>OUR COMMUNITY WILL WORK ALONGSIDE MARVEL, SONY & NETFLIX</JungleTextH3>
                    <JungleTextH3>3D ARTISTS TO CREATE A 1 MILLION USD ANIMATED SERIES. HALF OF THE PROFITS WILL BE FOR YOU!</JungleTextH3>
                 </JungleRight>
              </JungleDescription>
            </JungleContainer>
          </JungleHome>
      </Desktop>
      <Mobile>
        <JungleHome>
            <JungleContainer>
              <JungleDescription>
                  <JungleLeft>
                      <JungleTopTitle>
                        <JungleTextH1>GET YOUR PROFITS</JungleTextH1>
                      </JungleTopTitle>
                      <JungleTitle>
                        <a href='https://brainless-spikes.gitbook.io/brainless-spikes-wiki/overview/rewards'><JungleImageTitle src={title} type='image/png' /></a>
                      </JungleTitle>
                      <JungleTextH3>BRAINLESS SPIKES IS AN AWESOME APE BREED THAT WILL BLOW UP</JungleTextH3>
                      <JungleTextH3>THE NFT GLOBAL MARKET.</JungleTextH3>
                      <JungleTextH3>THE SPIKES ARE LIVING IN THE ETHEREUM BLOCKCHAIN ORGANIZED IN</JungleTextH3>
                      <JungleTextH3>A 2.000 EXCLUSIVE SUPPLY COLLECTION.</JungleTextH3>
                      <JungleTextH3>OUR COMMUNITY WILL WORK ALONGSIDE MARVEL, SONY & NETFLIX</JungleTextH3>
                      <JungleTextH3>3D ARTISTS TO CREATE A 1 MILLION USD ANIMATED SERIES. HALF OF THE PROFITS WILL BE FOR YOU!</JungleTextH3>
                 </JungleLeft>
                 <JungleRight>
                      <JungleKing>
                          <JungleImageTitle src={devil} type='image/png' />
                      </JungleKing>
                 </JungleRight>
              </JungleDescription>
            </JungleContainer>
          </JungleHome>
      </Mobile>
</div>
  )

export default Jungle