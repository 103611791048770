import React from 'react'

import { FaInstagram, FaDiscord, FaXTwitter, FaTiktok } from "react-icons/fa6"
import connect from "../../images/connect_wallet.png"

import { SocialIconLink, SidebarContainer, SideSocial, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarRoute, SideBtnWrap, ImageConnect, SidebarMembers } from './SidebarElements'

const GoMint = () => {window.open('https://opensea.io/collection/brainless-spikes-originals');}

const Sidebar = ({isOpen,toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
          <SidebarMenu>
                <SidebarLink to='features' onClick={toggle}>FEATURES</SidebarLink>
                <SidebarLink to='roadmap' onClick={toggle}>ROADMAP</SidebarLink>
                <SidebarLink to='team' onClick={toggle}>TEAM</SidebarLink>
                <SidebarLink to='rarity' onClick={toggle}>TRAIT RARITY</SidebarLink>
                <SidebarMembers href='https://members.brainlesspikes.io' target='_blank' onClick={toggle}>MEMBER'S ZONE</SidebarMembers>
                <SidebarLink to='faq' onClick={toggle}>FAQ's</SidebarLink>
            </SidebarMenu>
        <SideBtnWrap>
            <SidebarRoute onClick={GoMint}>
                <ImageConnect src={connect} type='image/png'/>
            </SidebarRoute>
        </SideBtnWrap>
        <SideSocial>
                <SocialIconLink href='https://discord.gg/JZ3QJNf2zu' target='_blank' arial-label='Discord'><FaDiscord /></SocialIconLink>
                <SocialIconLink href='https://www.instagram.com/brainlesspikes/' target='_blank' arial-label='Instagram'><FaInstagram /></SocialIconLink>
                <SocialIconLink href='https://twitter.com/BrainlesSpikes' target='_blank' arial-label='Twitter'><FaXTwitter/></SocialIconLink>
                <SocialIconLink href='https://www.tiktok.com/@brainlesspikes' target='_blank' arial-label='TikTok'><FaTiktok/></SocialIconLink>
        </SideSocial>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
