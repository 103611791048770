import styled from 'styled-components'

export const SubscribeHome = styled.div`
  display:flex;
  justify-content: center;
`
export const SubscribeContainer = styled.div`
  display: flex;
  max-width: 1920px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
`
export const SubscribeTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 20px 40px 20px;
`
export const SubscribeDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }  
`
export const SubscribeImageTitle = styled.img`
  height: 120px;
  width: auto;
`
export const SubscribeText = styled.div`
  max-width: 900px;
  width: 100%;
  font-family: "LemonMilk-regular";
  font-size: 18px;
  color: #6aacd8;
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }  
`

export const SuscribeBox = styled.div`
  display: flex;  
  margin-top: 15px;
  padding: 10px;
  max-width: 800px;
  width: 100%;
  border-radius: 64px;
  background-color: white;
  
  align-items: center;

  @media screen and (max-width: 767px) {
    width: 100%;
  }  
` 

export const SubscribeField = styled.input`
  width: 95%;
  background-color: transparent;
  text-align: center;
  font-family: "LemonMilk-regular";
  font-size: 14px;
  border: none;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (max-width: 767px) {
    width: 90%;
  }  
`

export const SubscribeNotify = styled.div`
  max-width: 900px;
  width: 100%;
  font-family: "LemonMilk-regular";
  font-size: 18px;
  color: white;
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
  margin-top: 15px;

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }  
`
export const SubscribeIconLink = styled.a`
  color: black;
  font-size: 24px;
`