import React from 'react'
import { FaInstagram, FaDiscord, FaXTwitter, FaTiktok } from "react-icons/fa6";
import { FooterContainer, SocialIconLink, SocialIcons, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights, ImageLogo } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll';
import logo from "../../images/bs-logo.png";
import logofl from "../../images/fl-logo.png"

const Footer = () => {
  const toggleHome = () => { scroll.scrollToTop(); }
  const GoFireLight =() => { window.open('https://firelight-ent.com'); }
  
  return (
    <FooterContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialIcons>
              <SocialIconLink href='https://discord.gg/JZ3QJNf2zu' target='_blank' arial-label='Discord'>
                <FaDiscord />
              </SocialIconLink>
              <SocialIconLink href='https://www.instagram.com/brainlesspikes/' target='_blank' arial-label='Instagram'>
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href='https://twitter.com/BrainlesSpikes' target='_blank' arial-label='Twitter'>
                <FaXTwitter />
              </SocialIconLink>
              <SocialIconLink href='https://www.tiktok.com/@brainlesspikes' target='_blank' arial-label='TikTok'>
                <FaTiktok />
              </SocialIconLink>
            </SocialIcons>
            <WebsiteRights>© COPYRIGHT-{new Date().getFullYear()} BRAINLESS SPIKES. ALL RIGHTS RESERVED.</WebsiteRights>
            <SocialLogo>
              <ImageLogo onClick={toggleHome} src={logo} type='image/png' />
              <ImageLogo onClick={GoFireLight} src={logofl} type='image/png' />
            </SocialLogo>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterContainer>
  )
}

export default Footer
