import React from "react";

import title from '../../images/roadmap.png'
import nro01 from '../../images/01.png'
import nro02 from '../../images/02.png'
import nro03 from '../../images/03.png'
import nro04 from '../../images/04.png'
import nro05 from '../../images/05.png'
import nro06 from '../../images/06.png'
import nro07 from '../../images/07.png'
import nro08 from '../../images/08.png'
import nro09 from '../../images/09.png'
import nro10 from '../../images/10.png'

import { RoadmapHome, RoadmapContainer, RoadmapTitle, RoadmapImageTitle, RoadmapStep, RoadmapNroLeft, RoadmapNroRight, RoadmapLeft, RoadmapRight, RoadmapTextRightH1, RoadmapTextRightH2, RoadmapTextRightH3, RoadmapTextLeftH1, RoadmapTextLeftH2, RoadmapTextLeftH3} from "./RoadmapElements";

function Roadmap() {
return (
    <RoadmapHome id='roadmap'>
        <RoadmapContainer>
            <RoadmapTitle>
                <RoadmapImageTitle src={title} type='image/png' />
            </RoadmapTitle>
            <RoadmapStep data-aos="fade-up">
                <RoadmapLeft >
                    <RoadmapNroLeft src={nro01} type='image/png'/>
                </RoadmapLeft>
                <RoadmapRight>
                    <RoadmapTextRightH1>10 %</RoadmapTextRightH1>
                    <RoadmapTextRightH2>FOCUSED ON ART QUALITY AND COMMUNITY</RoadmapTextRightH2>
                    <RoadmapTextRightH3>The art team is 100% focused on the art quality to create a super exclusive NFT collection. At the same time, part of the marketing team will be creating a huge community on social media.</RoadmapTextRightH3>
                </RoadmapRight>
            </RoadmapStep>
            <RoadmapStep data-aos="fade-up">
                <RoadmapLeft>
                    <RoadmapTextLeftH1>20 %</RoadmapTextLeftH1>
                    <RoadmapTextLeftH2>MINT LAUNCH</RoadmapTextLeftH2>
                    <RoadmapTextLeftH3>We are working hard to ensure a smooth mint… Visit our website and mint your own Brainless Spike. 70% of the NFT’s will be for whitelist members. The 30% will be sold on public sale.</RoadmapTextLeftH3>
                </RoadmapLeft>
                <RoadmapRight>
                    <RoadmapNroRight src={nro02} type='image/png'/>
                </RoadmapRight>
            </RoadmapStep>
            <RoadmapStep data-aos="fade-up">
                <RoadmapLeft>
                    <RoadmapNroLeft src={nro03} type='image/png'/>
                </RoadmapLeft>
                <RoadmapRight>
                    <RoadmapTextRightH1>30 %</RoadmapTextRightH1>
                    <RoadmapTextRightH2>2023 FIRST QUARTER - BIBLE SERIES CREATION</RoadmapTextRightH2>
                    <RoadmapTextRightH3>Our 3D Firelight Entertainment animation team will start producing the BIBLE of the Brainless Spikes series: basic script, synopsis, character concept arts & environments.</RoadmapTextRightH3>
                </RoadmapRight>
            </RoadmapStep>
            <RoadmapStep data-aos="fade-up">
                <RoadmapLeft>
                    <RoadmapTextLeftH1>40 %</RoadmapTextLeftH1>
                    <RoadmapTextLeftH2>2023 FIRST QUARTER - NFT'S LICENSES</RoadmapTextLeftH2>
                    <RoadmapTextLeftH3>Our holders must license their NFT in our website to receive the project rewards, and to see their NFT's characters in the animation series. This process has no cost.</RoadmapTextLeftH3>
                </RoadmapLeft>
                <RoadmapRight>
                    <RoadmapNroRight src={nro04} type='image/png'/>
                </RoadmapRight>
            </RoadmapStep>
            <RoadmapStep data-aos="fade-up">
                <RoadmapLeft>
                    <RoadmapNroLeft src={nro05} type='image/png'/>
                </RoadmapLeft>
                <RoadmapRight>
                    <RoadmapTextRightH1>50 %</RoadmapTextRightH1>
                    <RoadmapTextRightH2>2023 FIRST QUARTER - SPIKE AS A 3D INFLUENCER</RoadmapTextRightH2>
                    <RoadmapTextRightH3>Add brand value to Brainless Spikes project being viral on social media. Creating 3D animated viral content and get funds with marketing & branding campaings.</RoadmapTextRightH3>
                </RoadmapRight>
            </RoadmapStep>
            <RoadmapStep data-aos="fade-up">
                <RoadmapLeft>
                    <RoadmapTextLeftH1>60 %</RoadmapTextLeftH1>
                    <RoadmapTextLeftH2>2023 - COMMUNITY PRODUCTION & PARTNERSHIPS</RoadmapTextLeftH2>
                    <RoadmapTextLeftH3>Through multiple voting tools, our community will choose how our artists must develop the series & pilot production. We will also get partnership deals with other projects to create the series.</RoadmapTextLeftH3>
                </RoadmapLeft>
                <RoadmapRight>
                    <RoadmapNroRight src={nro06} type='image/png'/>
                </RoadmapRight>
            </RoadmapStep>
            <RoadmapStep data-aos="fade-up">
                <RoadmapLeft>
                    <RoadmapNroLeft src={nro07} type='image/png'/>
                </RoadmapLeft>
                <RoadmapRight>
                    <RoadmapTextRightH1>70 %</RoadmapTextRightH1>
                    <RoadmapTextRightH2>2023 - SEASON 2 LAUNCHING</RoadmapTextRightH2>
                    <RoadmapTextRightH3>Firelight Entertainment will launch his next NFT collection, web3 & Metaverse experience, while producing new animation series as well. The new character will appear in the 3D animation series. Holders who did a payed mint in the first Spike season will get a free Airdrop.</RoadmapTextRightH3>
                </RoadmapRight>
            </RoadmapStep>
            <RoadmapStep data-aos="fade-up">
                <RoadmapLeft>
                    <RoadmapTextLeftH1>80 %</RoadmapTextLeftH1>
                    <RoadmapTextLeftH2>TBA - DEAL WITH DIGITAL PLATFORMS</RoadmapTextLeftH2>
                    <RoadmapTextLeftH3>As a Marvel, Sony Pictures & Netflix 3D artists, our Firelight Entertainment team will manage the offers of the main digital platforms to sell the production and broadcast Brainless Spikes animation series.Our holders must license their NFT in our website to receive the project rewards, and to see their NFT's characters in the animation series. This process has no cost.</RoadmapTextLeftH3>
                </RoadmapLeft>
                <RoadmapRight>
                    <RoadmapNroRight src={nro08} type='image/png'/>
                </RoadmapRight>
            </RoadmapStep>
            <RoadmapStep data-aos="fade-up">
                <RoadmapLeft>
                    <RoadmapNroLeft src={nro09} type='image/png'/>
                </RoadmapLeft>
                <RoadmapRight>
                    <RoadmapTextRightH1>90 %</RoadmapTextRightH1>
                    <RoadmapTextRightH2>2023 - EVENTS & EXCLUSIVE CONTENT</RoadmapTextRightH2>
                    <RoadmapTextRightH3>During the whole production process, our holders and the community will have meetings with the art team to discover unseen and exclusive content about the series. We will organize face-to-face events as well.</RoadmapTextRightH3>
                </RoadmapRight>
            </RoadmapStep>
            <RoadmapStep data-aos="fade-up">
                <RoadmapLeft>
                    <RoadmapTextLeftH1>100 %</RoadmapTextLeftH1>
                    <RoadmapTextLeftH2>TBA - HOLDERS REWARDS & PREMIER</RoadmapTextLeftH2>
                    <RoadmapTextLeftH3>We will push as hard as we can to sell our 3D animation series to a digital broadcasting platform. 50% of the income generated will be transferred to our holders wallets. The community will be represented in the official series premiere.</RoadmapTextLeftH3>
                </RoadmapLeft>
                <RoadmapRight>
                    <RoadmapNroRight src={nro10} type='image/png'/>
                </RoadmapRight>
            </RoadmapStep>
        </RoadmapContainer >
        </RoadmapHome>
    );
}

export default Roadmap;
