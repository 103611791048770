import React from 'react'

import { Swiper, SwiperSlide }  from 'swiper/react/swiper-react.js';
import { Navigation } from 'swiper'
import { useMediaQuery } from 'react-responsive'

import 'swiper/swiper-bundle';

import "../../App.css"

import title from '../../images/rarity.png'
import soldier from '../../images/soldier.png'
import epic from '../../images/epic.png'
import legendary from '../../images/legendary.png'
import gift from '../../images/gift.png'
import sword from '../../images/sword.png'

import {RarityHome, RarityContainer, RarityTitle, RarityImageTitle, RaritySubTitle, RarityH1, RarityH2, RarityTraits, Soldier, SoldierBox, SoldierNFT, SoldierNFTh1, Epic, EpicBox, EpicNFT, EpicNFTh1, Legendary, LegendaryBox, LegendaryNFT,LegendaryNFTh1, Gift, GiftBox, GiftNFT, GiftNFTh1, NFTh2, ImgNFT} from './RarityElements'
import {EffectCoverflow} from "swiper";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1224 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1224 })
  return isMobile ? children : null
}

const Rarity = () => (
<div id='rarity'>
    <Desktop>
      <RarityHome>
        <RarityContainer>
          <RarityTitle>
            <RarityImageTitle src={title} type='image/png' />
          </RarityTitle>
          <RaritySubTitle>
            <RarityH1>ALL SPIKES ARE EQUAL, BUT SOME OF THEM ARE RARER THAN OTHERS. THAT'S WHY WE HAVE ESTABLISHED A TRAIT RARITY SYSTEM TO CLASSIFY OUR NFT'S COLLECTION. THERE ARE FOUR TOTAL TRAITS IN THE BRAINLESS SPIKES FAMILY:</RarityH1>
            <RarityH2>SWORD, SOLDIER, EPIC, LEGENDARY AND GIFT.</RarityH2>
            <RarityH1>DEPENDING ON THE NFT RARITY LEVEL; THE PRODUCT EXCLUSIVITY AND QUALITY WILL VARY. AS A RESULT, THE REWARDS THAT OUR HOLDERS WILL OBTAIN THROUGH BE DIFFERENT AS WELL.</RarityH1>
          </RaritySubTitle>
          <RarityTraits>
          <Swiper
            modules={[EffectCoverflow, Navigation]}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            navigation
            slidesPerView={3}
            loop={true}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}>
              <SwiperSlide>
                <Soldier>
                  <SoldierBox>
                  <SoldierNFT>
                      <ImgNFT src={soldier} type='image/png'/>
                  </SoldierNFT>
                  <SoldierNFTh1>SOLDIER</SoldierNFTh1>
                      <NFTh2>960 NFT'S</NFTh2>
                      <NFTh2>ROYALTIES</NFTh2>
                      <NFTh2>STATIC IMAGE WITH MULTIPLE POSITIONS</NFTh2>
                      <NFTh2>DESIGN CHARACTERS TO CONNECT WITH OUR AUDIENCE</NFTh2>
                      <NFTh2>CHARACTER WILL EXPERIENCE DIFFERENT EPIC AND FUNNY SITUATIONS</NFTh2>
                  </SoldierBox>
              </Soldier>
            </SwiperSlide>
            <SwiperSlide>
              <Epic>
              <EpicBox>
                <EpicNFT> 
                  <ImgNFT src={epic} type='image/png'/>
                </EpicNFT>
                  <EpicNFTh1>EPIC</EpicNFTh1>
                  <NFTh2>15 NFT'S</NFTh2>
                  <NFTh2>ROYALTIES</NFTh2>
                  <NFTh2>ONE LEVEL ABOVE SOLDIERS</NFTh2>
                  <NFTh2>STATIC IMAGE WITH MULTIPLE POSITIONS</NFTh2>
                  <NFTh2>PREMIUM APPEARANCE, OBJECTS AND ASSETS</NFTh2>
                  <NFTh2>HIGHER REWARDS FOR OUR HOLDERS</NFTh2>
                </EpicBox>
              </Epic>
            </SwiperSlide>
            <SwiperSlide>
            <Legendary>
                <LegendaryBox>
                  <LegendaryNFT> 
                    <ImgNFT src={legendary} type='image/png'/>
                  </LegendaryNFT>
                  <LegendaryNFTh1>LEGENDARY</LegendaryNFTh1>
                  <NFTh2>5 NFT'S</NFTh2>
                  <NFTh2>ROYALTIES</NFTh2>
                  <NFTh2>ONE LEVEL ABOVE EPICS</NFTh2>
                  <NFTh2>THE HIGHEST RARITY LEVEL</NFTh2>
                  <NFTh2>3D ANIMATION NFT'S: SHORT ANIMATION CLIPS</NFTh2>
                  <NFTh2>THE HIGHEST REWARD OUR HOLDERS</NFTh2>
                </LegendaryBox>
            </Legendary>
            </SwiperSlide>
            <SwiperSlide>
            <Gift>
                <GiftBox>
                  <GiftNFT> 
                    <ImgNFT src={gift} type='image/png'/>
                  </GiftNFT>
                  <GiftNFTh1>GIFT</GiftNFTh1>
                  <NFTh2>SECRET. COMING SOON</NFTh2>
                  <NFTh2>BONUS TRACK RARITY LEVEL, BETWEEN EPIC AND LEGENDARY</NFTh2>
                  <NFTh2>GIFTS FOR OUR HOLDERS</NFTh2>
                  <NFTh2>NFT'S LINKED TO IMPORTANT EVENTS OF THE YEAR</NFTh2>
                  <NFTh2>UTILITY FOR THE PROJECT SECOND SEASON</NFTh2>
                </GiftBox>
            </Gift>
            </SwiperSlide>
            <SwiperSlide>
                <Legendary>
                  <LegendaryBox>
                      <SoldierNFT>
                          <ImgNFT src={sword} type='image/png'/>
                      </SoldierNFT>
                      <LegendaryNFTh1>SWORDS</LegendaryNFTh1>
                      <NFTh2>1000 NFT'S</NFTh2>
                      <NFTh2>NOT ROYALTIES</NFTh2>
                      <NFTh2>FREE MINT</NFTh2>
                      <NFTh2>STATIC IMAGE WITH MULTIPLE POSITIONS</NFTh2>
                      <NFTh2>SAME AS SOLDIERS BUT WITH DIFFERENT BACKGROUND</NFTh2>
                      <NFTh2>LOWEST RARITY RANGE</NFTh2>
                  </LegendaryBox>
              </Legendary>
            </SwiperSlide>
            </Swiper>
          </RarityTraits>
      </RarityContainer>
      </RarityHome>
    </Desktop>
    <Mobile>
      <RarityHome id='rarity'>
        <RarityContainer>
          <RarityTitle>
            <RarityImageTitle src={title} type='image/png' />
          </RarityTitle>
          <RaritySubTitle>
            <RarityH1>ALL SPIKES ARE EQUAL, BUT SOME OF THEM ARE RARER THAN OTHERS. THAT'S WHY WE HAVE ESTABLISHED A TRAIT RARITY SYSTEM TO CLASSIFY OUR NFT'S COLLECTION. THERE ARE FOUR TOTAL TRAITS IN THE BRAINLESS SPIKES FAMILY:</RarityH1>
            <RarityH2>SOLDIER, EPIC, LEGENDARY AND GIFT.</RarityH2>
            <RarityH1>DEPENDING ON THE NFT RARITY LEVEL; THE PRODUCT EXCLUSIVITY AND QUALITY WILL VARY. AS A RESULT, THE REWARDS THAT OUR HOLDERS WILL OBTAIN THROUGH BE DIFFERENT AS WELL.</RarityH1>
          </RaritySubTitle>
          <RarityTraits>
          <Swiper
            modules={[EffectCoverflow, Navigation]}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            navigation
            slidesPerView={1}
            loop={true}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}>
              <SwiperSlide>
                <Soldier>
                  <SoldierBox>
                      <SoldierNFT>
                          <ImgNFT src={soldier} type='image/png'/>
                      </SoldierNFT>
                      <SoldierNFTh1>SOLDIER</SoldierNFTh1>
                      <NFTh2>960 NFT'S</NFTh2>
                      <NFTh2>ROYALTIES</NFTh2>
                      <NFTh2>STATIC IMAGE WITH MULTIPLE POSITIONS</NFTh2>
                      <NFTh2>DESIGN CHARACTERS TO CONNECT WITH OUR AUDIENCE</NFTh2>
                      <NFTh2>CHARACTER WILL EXPERIENCE DIFFERENT EPIC AND FUNNY SITUATIONS</NFTh2>
                  </SoldierBox>
              </Soldier>
            </SwiperSlide>
            <SwiperSlide>
              <Epic>
              <EpicBox>
                <EpicNFT> 
                  <ImgNFT src={epic} type='image/png'/>
                </EpicNFT>
                  <EpicNFTh1>EPIC</EpicNFTh1>
                  <NFTh2>15 NFT'S</NFTh2>
                  <NFTh2>ROYALTIES</NFTh2>
                  <NFTh2>ONE LEVEL ABOVE SOLDIERS</NFTh2>
                  <NFTh2>STATIC IMAGE WITH MULTIPLE POSITIONS</NFTh2>
                  <NFTh2>PREMIUM APPEARANCE, OBJECTS AND ASSETS</NFTh2>
                  <NFTh2>HIGHER REWARDS FOR OUR HOLDERS</NFTh2>
                </EpicBox>
              </Epic>
            </SwiperSlide>
            <SwiperSlide>
            <Legendary>
                <LegendaryBox>
                  <LegendaryNFT> 
                    <ImgNFT src={legendary} type='image/png'/>
                  </LegendaryNFT>
                  <LegendaryNFTh1>LEGENDARY</LegendaryNFTh1>
                  <NFTh2>5 NFT'S</NFTh2>
                  <NFTh2>ROYALTIES</NFTh2>
                  <NFTh2>ONE LEVEL ABOVE EPICS</NFTh2>
                  <NFTh2>THE HIGHEST RARITY LEVEL</NFTh2>
                  <NFTh2>3D ANIMATION NFT'S: SHORT ANIMATION CLIPS</NFTh2>
                  <NFTh2>THE HIGHEST REWARD OUR HOLDERS</NFTh2>
                </LegendaryBox>
            </Legendary>
            </SwiperSlide>
            <SwiperSlide>
            <Gift>
                <GiftBox>
                  <GiftNFT> 
                    <ImgNFT src={gift} type='image/png'/>
                  </GiftNFT>
                  <GiftNFTh1>GIFT</GiftNFTh1>
                  <NFTh2>SECRET. COMING SOON</NFTh2>
                  <NFTh2>BONUS TRACK RARITY LEVEL, BETWEEN EPIC AND LEGENDARY</NFTh2>
                  <NFTh2>GIFTS FOR OUR HOLDERS</NFTh2>
                  <NFTh2>NFT'S LINKED TO IMPORTANT EVENTS OF THE YEAR</NFTh2>
                  <NFTh2>UTILITY FOR THE PROJECT SECOND SEASON</NFTh2>
                </GiftBox>
            </Gift>
            </SwiperSlide>
            <SwiperSlide>
                <Legendary>
                  <LegendaryBox>
                      <SoldierNFT>
                          <ImgNFT src={sword} type='image/png'/>
                      </SoldierNFT>
                      <LegendaryNFTh1>SWORDS</LegendaryNFTh1>
                      <NFTh2>1000 NFT'S</NFTh2>
                      <NFTh2>NOT ROYALTIES</NFTh2>
                      <NFTh2>FREE MINT</NFTh2>
                      <NFTh2>STATIC IMAGE WITH MULTIPLE POSITIONS</NFTh2>
                      <NFTh2>SAME AS SOLDIERS BUT WITH DIFFERENT BACKGROUND</NFTh2>
                      <NFTh2>LOWEST RARITY RANGE</NFTh2>
                  </LegendaryBox>
              </Legendary>
            </SwiperSlide>
            </Swiper>
          </RarityTraits>
      </RarityContainer>
      </RarityHome>
    </Mobile>
  </div>
)

export default Rarity
